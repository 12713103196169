import React from 'react'
import { Link } from 'react-router-dom'
import Ser from "../assets/Doorman service.svg"
const Hospitality = () => {
    return (
        <div className='flex justify-center max-md:px-5 max-md:order-2 '>
            <div className='max-w-[1280px] px-24 max-md:px-0 rounded-2xl  border border-solid  bg-[#F2F2F2] border-[color:var(--Gray-4,#BDBDBD)]'>
                <div className=" py-5  max-md:px-5 max-md:w-[100%]">
                    <div className="flex gap-5 max-md:flex-col max-md:justify-center max-md:gap-0">
                        <div className="flex flex-col  max-md:ml-0 max-md:w-full  order-2">
                            <div className="flex flex-col  justify-end self-stretch my-auto font-semibold max-md:mt-5 max-md:max-w-full">
                                <div className="text-3xl text-[#333333] lg:flex lg:justify-end max-md:max-w-full max-md:text-2xl max-md:text-center lg:w-[750px]">
                                    Get a 15 days free trial
                                </div>
                                <div className="flex lg:justify-end max-md:justify-center mt-4">
                                    <Link to="https://app.eazotel.com/generatewebsite" target='_blank' className="px-14 max-md:px-5 py-3.5 text-base text-white bg-[#FD5C01] rounded-lg whitespace-nowrap">
                                        Create Website
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col items-end ml-5 w-[19%] max-md:ml-0 max-md:w-full max-md:items-center ">
                            <img
                                loading="lazy"
                                src={Ser}
                                className="w-40 max-w-full aspect-square max-md:mt-5 max-md:order-1"
                                alt='hospitality'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hospitality